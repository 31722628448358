import React from 'react';
import axios from 'axios';
import JustinKanProfileImage from './JustinKanProfileImage.png';

const NFT_Lookup = {
  '63456874504071853140487069918655519200396087339641441958069865020882191122433': {
    supply: 1,
    token_id: '63456874504071853140487069918655519200396087339641441958069865020882191122433',
    name: 'I Almost Sold To Yahoo For $1 Million At 22 | Certified Justin Kan Stories',
    image_url:
      'https://lh3.googleusercontent.com/FSnqU4p5uEO0_9YT1vx22gzIKFpiStmvJAb3_iUaY7s8IfCuUh_qlivQFCsqT8y4rKzdEo_uwJ16LVwZbwpvCHEQkr99jMK9e4SA',
    creator: { user: { username: 'JustinKan' } },
  },
  '63456874504071853140487069918655519200396087339641441958069865023081214377985': {
    supply: 1,
    token_id: '63456874504071853140487069918655519200396087339641441958069865023081214377985',
    name: 'Why I Was "Successful" But Unhappy | Certified Justin Kan Stories',
    image_url:
      'https://lh3.googleusercontent.com/Ml_h5qjywSJ7DR-_OrW7UA_f07_e63XmAnpPz0G7RWD_Kunqpjk3xfYnNP6Df9TzZVWxUnhoAn1XZiq1bewzq628g2rgD2cuwFDvTA',
    creator: { user: { username: 'JustinKan' } },
  },
  '63456874504071853140487069918655519200396087339641441958069865021981702750209': {
    supply: 1,
    token_id: '63456874504071853140487069918655519200396087339641441958069865021981702750209',
    name: 'I Sold My Startup On Ebay For $258,100 | Certified Justin Kan Stories',
    image_url:
      'https://lh3.googleusercontent.com/R_ayBtV4ykgRv5-kmx2N0-CbOxpaKlS_e0SjtufNx68OypitpzP6VFTTOsENwPQOIR_syMYQqCjsf1ZsS1xDvB03sgNIC6phh1TXwZQ',
    creator: { user: { username: 'JustinKan' } },
  },
  '63456874504071853140487069918655519200396087339641441958069865018683167866881': {
    supply: 1,
    token_id: '63456874504071853140487069918655519200396087339641441958069865018683167866881',
    name: 'How My First Startup Got Picked For Y Combinator | Certified Justin Kan Stories',
    image_url:
      'https://lh3.googleusercontent.com/N_MoMaInheLDkMLjwp0YXkUyW523_FKVKc7kjy2u4Emu6jw5Ma_WGkpbLD1paJdRm1eGyMwiKnsQ7GH4oXWYKckfTYyEp02mJL8e5w',
    creator: { user: { username: 'JustinKan' } },
  },
  '63456874504071853140487069918655519200396087339641441958069865017583656239105': {
    supply: 1,
    token_id: '63456874504071853140487069918655519200396087339641441958069865017583656239105',
    name: 'I Gave Away $10,000 and Sold All My NFTs | Certified Justin Kan Stories',
    image_url:
      'https://lh3.googleusercontent.com/wS44wB0mcTu32JxHAEHlfyAmVamBbU1k0uYPHoDWf5niSmTgESGm_sJufkpP4H-2V0ulITa2W1vukqGWMgvX_CWTz0gaVfpiAnG5',
    creator: { user: { username: 'JustinKan' } },
  },
  '63456874504071853140487069918655519200396087339641441958069865019782679494657': {
    supply: 1,
    token_id: '63456874504071853140487069918655519200396087339641441958069865016484144611329',
    name: 'Do I regret selling Twitch? | Certified Justin Kan Stories',
    image_url:
      'https://lh3.googleusercontent.com/D_MZVE5ep5sMmSlq2Fu9X85G7NcwNN4ax9eiU5cc1VhO66xTZU0zxA78wUs3lITp6SMbxx_p3_cl1SgJ7zOduClegq6IPwWZgkhINAU',
    creator: { user: { username: 'JustinKan' } },
  },
  '63456874504071853140487069918655519200396087339641441958069865016484144611329': {
    supply: 1,
    token_id: '63456874504071853140487069918655519200396087339641441958069865016484144611329',
    name: 'How Twitch Reached $1,000,000/Month in Revenue | Certified Justin Kan Stories',
    image_url:
      'https://lh3.googleusercontent.com/vcIBE_kkXiOeyMrAHlDT6vLAZ6u_lDOVP1t1AfpiPH5V4BznbOLc2kVD-vgtHacAoNHtLwjR2sInKLxCtQDUxHoNxCt5pjD7kfslLw',
    creator: { user: { username: 'JustinKan' } },
  },
  '63456874504071853140487069918655519200396087339641441958069865015384632983553': {
    supply: 1,
    token_id: '63456874504071853140487069918655519200396087339641441958069865015384632983553',
    name: 'This Is Why I Meditate Everyday | Certified Justin Kan Stories',
    image_url:
      'https://lh3.googleusercontent.com/IGoyTS9SpLQ_t_OGDRvdZs1rOTE6BBrju7RKJ7mIBC_nlsHMeQlGFS6_cl3NmMFsT74aI0Ee6FDayGN-e5u0yCOXlnh_mGg2uHIf',
    creator: { user: { username: 'JustinKan' } },
  },
  '63456874504071853140487069918655519200396087339641441958069865014285121355777': {
    supply: 1,
    token_id: '63456874504071853140487069918655519200396087339641441958069865014285121355777',
    name: 'MY SECRET TIPS for pitching your startup to investors | Certified Justin Kan Stories',
    image_url:
      'https://lh3.googleusercontent.com/Qqd1JhHcW-1xo-EWD-eztBiufj1iHVccZT5b8OHzuHT8ZTniewcMhWNBD_ojqn_hrL6CLH_hAed9Rmji1gxJcNv8QSlexp7rLHi_3w',
    creator: { user: { username: 'JustinKan' } },
  },
  '63456874504071853140487069918655519200396087339641441958069865013185609728001': {
    supply: 1,
    token_id: '63456874504071853140487069918655519200396087339641441958069865013185609728001',
    name: 'You can COLLECT my YouTube videos as an NFT?! | Certified Justin Kan Stories',
    image_url:
      'https://lh3.googleusercontent.com/ad0QiNuCLIWXB740FM13Fbmsfh2FAzdLr7_dCQpq2AdQNExRc8mMCvfLsjnxfDZq6hreCl1klHwJgUapNfsSp09TtsixU_i5z_jH',
    creator: { user: { username: 'JustinKan' } },
  },
  '63456874504071853140487069918655519200396087339641441958069865007688051589121': {
    supply: 1,
    token_id: '63456874504071853140487069918655519200396087339641441958069865007688051589121',
    name: 'How we ALMOST sold Twitch to Google for $1 BILLION | Certified Justin Kan Stories',
    image_url:
      'https://lh3.googleusercontent.com/FWjDRWp3LhLlZc-k2KEzRAB3kaW-_0VTJuCjEkDBhR646kP__I0yLATQnkJhn-vKXhLhgBnN7vDUlj0O3XaqH05XbhXbXl1au8sA',
    creator: { user: { username: 'JustinKan' } },
  },
  '63456874504071853140487069918655519200396087339641441958069865006588539961345': {
    supply: 1,
    token_id: '63456874504071853140487069918655519200396087339641441958069865006588539961345',
    name: 'Getting arrested with the Reddit CEO proved Justin.tv worked? | Certified Justin Kan Stories',
    image_url:
      'https://lh3.googleusercontent.com/VSw7wPQjC_x5G-Mluif3ZdEviwHee_pVxXQ0pSQKPVrHG6-YcroLtI0fQU1-7O8w3jYgdKuwh7vg1Do_xtj0Kdt8JeFYTJSkjP1o',
    creator: { user: { username: 'JustinKan' } },
  },
  '63456874504071853140487069918655519200396087339641441958069865012086098100274': {
    supply: 50,
    token_id: '63456874504071853140487069918655519200396087339641441958069865012086098100274',
    name: 'We ALMOST started "TikTok" instead of Twitch?! | Certified Justin Kan Stories',
    image_url:
      'https://lh3.googleusercontent.com/43L6Lb2UkaSu0WYT9XWXYRPV-GNz8ZnTsfUgI7-CwxJuX88seC0hrV87rM93XZOJVELCSWmXjV0dRvoPWg9uds_6fcp0ahvdw7HvhA',
    creator: { user: { username: 'JustinKan' } },
  },
  '63456874504071853140487069918655519200396087339641441958069865010986586472545': {
    supply: 97,
    token_id: '63456874504071853140487069918655519200396087339641441958069865010986586472545',
    name: 'Selling Twitch to Amazon...the real story | Certified Justin Kan Stories',
    image_url:
      'https://lh3.googleusercontent.com/BVVwUS6Kq9XkmDM8B0ZWizisF2zzJqGswdXFfUAsTePoHPyDlZwlI5XqTqs0m7i4RDb8nKaZU1C2TwhJ-I8imMPkwJgK0ELJm2fyRBc',
    creator: { user: { username: 'JustinKan' } },
  },
};

const Questions = () => {
  const [questions, setQuestions] = React.useState([]);

  React.useEffect(() => {
    axios.get('https://us-central1-justin-nft.cloudfunctions.net/api/all_questions').then((result) => {
      let data = result.data;

      if (!data.error) {
        setQuestions(data.questions);
      }
    });
  }, []);

  return (
    <div className='min-h-screen bg-gray-100'>
      <nav className='bg-white shadow-sm'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
          <div className='flex justify-between h-16'>
            <div className='flex'>
              <div className='flex-shrink-0 flex items-center'>
                <img className='block lg:hidden h-8 w-auto' src={JustinKanProfileImage} alt='Workflow' />
                <img className='hidden lg:block h-8 w-auto' src={JustinKanProfileImage} alt='Workflow' />
                <h1 className='ml-4 text-2xl font-bold leading-tight text-gray-900'>Certified Justin Kan Stories</h1>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div
        className=''
        style={{
          minHeight: '6px',
          maxHeight: '6px',
          height: '6px',
          width: '100%',
          background: 'linear-gradient(to right, #12c2e9, #c471ed, #FAD6A6)',
        }}
      ></div>

      <div className='py-10'>
        <header>
          <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
            {/*
      <h1 className="text-3xl font-bold leading-tight text-gray-900">
        Dashboard
      </h1>
      */}
          </div>
        </header>

        <main>
          <div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
            {/* Replace with your content */}
            <h2 className='text-2xl mb-6 font-bold leading-7 text-gray-600 sm:text-3xl sm:truncate'>Questions</h2>

            <div className='flex flex-col'>
              <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                  <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                    <table className='min-w-full divide-y divide-gray-200'>
                      <thead className='bg-gray-50'>
                        <tr>
                          <th
                            scope='col'
                            className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                          >
                            NFT
                          </th>
                          <th
                            scope='col'
                            className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                          >
                            Sender
                          </th>
                          <th
                            scope='col'
                            className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                          >
                            Question
                          </th>
                        </tr>
                      </thead>
                      <tbody className='bg-white divide-y divide-gray-200'>
                        {questions.map((question) => (
                          <tr key={question.address}>
                            <td className='px-6 py-4 whitespace-nowrap'>
                              <a
                                target='_blank'
                                href={`https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/${question.nft_id}`}
                                className='flex items-center'
                              >
                                <div className='flex-shrink-0 h-10 w-18'>
                                  <img className='h-10 w-18' src={NFT_Lookup[question.nft_id].image_url} alt='' />
                                </div>
                                <div className='flex-shrink-1 ml-4'>
                                  <div className='text-sm font-medium text-gray-900 '>
                                    {NFT_Lookup[question.nft_id].name.slice(0, 25) + '...'}
                                  </div>
                                  <div className='text-sm text-gray-500'>{`1/${
                                    NFT_Lookup[question.nft_id].supply
                                  } NFTs`}</div>
                                </div>
                              </a>
                            </td>
                            <td className='px-6 py-4 whitespace-nowrap'>
                              <a target='_blank' href={`https://opensea.io/accounts/${question.address}`}>
                                <div className='text-sm font-medium text-gray-900'>{question.name}</div>
                                <div className='text-sm text-gray-500'>
                                  {question.address.slice(0, 5) +
                                    '...' +
                                    question.address.slice(question.address.length - 5)}
                                </div>
                              </a>
                            </td>
                            <td className='px-6 py-4 text-sm text-gray-500' style={{ maxWidth: '400px' }}>
                              {question.question}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* */}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Questions;
