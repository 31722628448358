import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import App from './App';
import Questions from './Questions';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyCHRft0a89YkQ9VDfeSrEQ-IfpMb8kRCUc',
  authDomain: 'justin-nft.firebaseapp.com',
  projectId: 'justin-nft',
  storageBucket: 'justin-nft.appspot.com',
  messagingSenderId: '822066449898',
  appId: '1:822066449898:web:e6eaec1d3b86cac93bf693',
  measurementId: 'G-SQSJ28X2QV',
};

if (!firebase.apps.length) {
  try {
    firebase.initializeApp(firebaseConfig);
  } catch (err) {
    console.error('Firebase initialization error raised', err.stack);
  }
}

firebase.analytics();
firebase.functions();

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path='/'>
          <App firebase={firebase} />
        </Route>
        <Route exact path='/questions'>
          <Questions firebase={firebase} />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
