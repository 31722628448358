import React from 'react';
import Web3 from 'web3';
import JustinKanProfileImage from './JustinKanProfileImage.png';
import erc1155ABI from './erc1155ABI.js';

import './App.css';
import axios from 'axios';

const formatNFT = (raw) => {
  return {
    token_id: raw.token_id,
    image_url: raw.image_url,
    animation_url: raw.animation_url,
    name: raw.name,
    description: raw.description,
    external_link: raw.external_link,
    permalink: raw.permalink,
    creator: raw.creator,
  };
};

function App() {
  const [hasWallet, setHasWallet] = React.useState(null);
  const [walletAddress, setWalletAddress] = React.useState(null);
  const [web3, setWeb3] = React.useState(null);
  const [isVerified, setIsVerified] = React.useState(null);
  const [walletOwnsNft, setWalletOwnsNft] = React.useState(null);
  const [ownedNFTs, setOwnedNFTs] = React.useState([]);
  const [currentStep, setCurrentStep] = React.useState(1);
  const [web3Loading, setWeb3Loading] = React.useState(false);
  const [NFTsLoading, setNFTsLoading] = React.useState(false);
  const [tokenVerifying, setTokenVerifying] = React.useState(null);
  const [zoomLink, setZoomLink] = React.useState('');
  const [displayQuestion, setDisplayQuestion] = React.useState(false);
  const [name, setName] = React.useState('');
  const [question, setQuestion] = React.useState('');
  const [isQuestionSubmitting, setIsQuestionSubmitting] = React.useState('');
  const [verifiedToken, setVerifiedToken] = React.useState('');
  const [submittedQuestion, setSubmittedQuestion] = React.useState({});
  const [hasSubmittedQuestion, setHasSubmittedQuestion] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(true);
  const [showQuestionSubmittedMessage, setShowQuestionSubmittedMessage] = React.useState(false);

  React.useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      setHasWallet(true);
    } else {
      setHasWallet(false);
      //console.log('no browser wallet');
    }
  }, []);

  React.useEffect(() => {
    const NFT_Address = '0x495f947276749ce646f68ac8c248420045cb7b5e';
    const ConfiguredNFTs = [
      '63456874504071853140487069918655519200396087339641441958069865013185609728001',
      '63456874504071853140487069918655519200396087339641441958069865007688051589121',
      '63456874504071853140487069918655519200396087339641441958069865006588539961345',
      '63456874504071853140487069918655519200396087339641441958069865012086098100274',
      '63456874504071853140487069918655519200396087339641441958069865010986586472545',
      '63456874504071853140487069918655519200396087339641441958069865014285121355777',
      '63456874504071853140487069918655519200396087339641441958069865015384632983553',
      '63456874504071853140487069918655519200396087339641441958069865016484144611329',
      '63456874504071853140487069918655519200396087339641441958069865019782679494657',
      '63456874504071853140487069918655519200396087339641441958069865017583656239105',
      '63456874504071853140487069918655519200396087339641441958069865018683167866881',
      '63456874504071853140487069918655519200396087339641441958069865021981702750209',
      '63456874504071853140487069918655519200396087339641441958069865023081214377985',
      '63456874504071853140487069918655519200396087339641441958069865020882191122433',
    ];
    const NFT_Lookup = {
      '63456874504071853140487069918655519200396087339641441958069865020882191122433': {
        supply: 1,
        token_id: '63456874504071853140487069918655519200396087339641441958069865020882191122433',
        name: 'I Almost Sold To Yahoo For $1 Million At 22 | Certified Justin Kan Stories',
        image_url:
          'https://lh3.googleusercontent.com/FSnqU4p5uEO0_9YT1vx22gzIKFpiStmvJAb3_iUaY7s8IfCuUh_qlivQFCsqT8y4rKzdEo_uwJ16LVwZbwpvCHEQkr99jMK9e4SA',
        creator: { user: { username: 'JustinKan' } },
      },
      '63456874504071853140487069918655519200396087339641441958069865023081214377985': {
        supply: 1,
        token_id: '63456874504071853140487069918655519200396087339641441958069865023081214377985',
        name: 'Why I Was "Successful" But Unhappy | Certified Justin Kan Stories',
        image_url:
          'https://lh3.googleusercontent.com/Ml_h5qjywSJ7DR-_OrW7UA_f07_e63XmAnpPz0G7RWD_Kunqpjk3xfYnNP6Df9TzZVWxUnhoAn1XZiq1bewzq628g2rgD2cuwFDvTA',
        creator: { user: { username: 'JustinKan' } },
      },
      '63456874504071853140487069918655519200396087339641441958069865021981702750209': {
        supply: 1,
        token_id: '63456874504071853140487069918655519200396087339641441958069865021981702750209',
        name: 'I Sold My Startup On Ebay For $258,100 | Certified Justin Kan Stories',
        image_url:
          'https://lh3.googleusercontent.com/R_ayBtV4ykgRv5-kmx2N0-CbOxpaKlS_e0SjtufNx68OypitpzP6VFTTOsENwPQOIR_syMYQqCjsf1ZsS1xDvB03sgNIC6phh1TXwZQ',
        creator: { user: { username: 'JustinKan' } },
      },
      '63456874504071853140487069918655519200396087339641441958069865018683167866881': {
        supply: 1,
        token_id: '63456874504071853140487069918655519200396087339641441958069865018683167866881',
        name: 'How My First Startup Got Picked For Y Combinator | Certified Justin Kan Stories',
        image_url:
          'https://lh3.googleusercontent.com/N_MoMaInheLDkMLjwp0YXkUyW523_FKVKc7kjy2u4Emu6jw5Ma_WGkpbLD1paJdRm1eGyMwiKnsQ7GH4oXWYKckfTYyEp02mJL8e5w',
        creator: { user: { username: 'JustinKan' } },
      },
      '63456874504071853140487069918655519200396087339641441958069865017583656239105': {
        supply: 1,
        token_id: '63456874504071853140487069918655519200396087339641441958069865017583656239105',
        name: 'I Gave Away $10,000 and Sold All My NFTs | Certified Justin Kan Stories',
        image_url:
          'https://lh3.googleusercontent.com/wS44wB0mcTu32JxHAEHlfyAmVamBbU1k0uYPHoDWf5niSmTgESGm_sJufkpP4H-2V0ulITa2W1vukqGWMgvX_CWTz0gaVfpiAnG5',
        creator: { user: { username: 'JustinKan' } },
      },
      '63456874504071853140487069918655519200396087339641441958069865019782679494657': {
        supply: 1,
        token_id: '63456874504071853140487069918655519200396087339641441958069865016484144611329',
        name: 'Do I regret selling Twitch? | Certified Justin Kan Stories',
        image_url:
          'https://lh3.googleusercontent.com/D_MZVE5ep5sMmSlq2Fu9X85G7NcwNN4ax9eiU5cc1VhO66xTZU0zxA78wUs3lITp6SMbxx_p3_cl1SgJ7zOduClegq6IPwWZgkhINAU',
        creator: { user: { username: 'JustinKan' } },
      },
      '63456874504071853140487069918655519200396087339641441958069865016484144611329': {
        supply: 1,
        token_id: '63456874504071853140487069918655519200396087339641441958069865016484144611329',
        name: 'How Twitch Reached $1,000,000/Month in Revenue | Certified Justin Kan Stories',
        image_url:
          'https://lh3.googleusercontent.com/vcIBE_kkXiOeyMrAHlDT6vLAZ6u_lDOVP1t1AfpiPH5V4BznbOLc2kVD-vgtHacAoNHtLwjR2sInKLxCtQDUxHoNxCt5pjD7kfslLw',
        creator: { user: { username: 'JustinKan' } },
      },
      '63456874504071853140487069918655519200396087339641441958069865015384632983553': {
        supply: 1,
        token_id: '63456874504071853140487069918655519200396087339641441958069865015384632983553',
        name: 'This Is Why I Meditate Everyday | Certified Justin Kan Stories',
        image_url:
          'https://lh3.googleusercontent.com/IGoyTS9SpLQ_t_OGDRvdZs1rOTE6BBrju7RKJ7mIBC_nlsHMeQlGFS6_cl3NmMFsT74aI0Ee6FDayGN-e5u0yCOXlnh_mGg2uHIf',
        creator: { user: { username: 'JustinKan' } },
      },
      '63456874504071853140487069918655519200396087339641441958069865014285121355777': {
        supply: 1,
        token_id: '63456874504071853140487069918655519200396087339641441958069865014285121355777',
        name: 'MY SECRET TIPS for pitching your startup to investors | Certified Justin Kan Stories',
        image_url:
          'https://lh3.googleusercontent.com/Qqd1JhHcW-1xo-EWD-eztBiufj1iHVccZT5b8OHzuHT8ZTniewcMhWNBD_ojqn_hrL6CLH_hAed9Rmji1gxJcNv8QSlexp7rLHi_3w',
        creator: { user: { username: 'JustinKan' } },
      },
      '63456874504071853140487069918655519200396087339641441958069865013185609728001': {
        supply: 1,
        token_id: '63456874504071853140487069918655519200396087339641441958069865013185609728001',
        name: 'You can COLLECT my YouTube videos as an NFT?! | Certified Justin Kan Stories',
        image_url:
          'https://lh3.googleusercontent.com/ad0QiNuCLIWXB740FM13Fbmsfh2FAzdLr7_dCQpq2AdQNExRc8mMCvfLsjnxfDZq6hreCl1klHwJgUapNfsSp09TtsixU_i5z_jH',
        creator: { user: { username: 'JustinKan' } },
      },
      '63456874504071853140487069918655519200396087339641441958069865007688051589121': {
        supply: 1,
        token_id: '63456874504071853140487069918655519200396087339641441958069865007688051589121',
        name: 'How we ALMOST sold Twitch to Google for $1 BILLION | Certified Justin Kan Stories',
        image_url:
          'https://lh3.googleusercontent.com/FWjDRWp3LhLlZc-k2KEzRAB3kaW-_0VTJuCjEkDBhR646kP__I0yLATQnkJhn-vKXhLhgBnN7vDUlj0O3XaqH05XbhXbXl1au8sA',
        creator: { user: { username: 'JustinKan' } },
      },
      '63456874504071853140487069918655519200396087339641441958069865006588539961345': {
        supply: 1,
        token_id: '63456874504071853140487069918655519200396087339641441958069865006588539961345',
        name: 'Getting arrested with the Reddit CEO proved Justin.tv worked? | Certified Justin Kan Stories',
        image_url:
          'https://lh3.googleusercontent.com/VSw7wPQjC_x5G-Mluif3ZdEviwHee_pVxXQ0pSQKPVrHG6-YcroLtI0fQU1-7O8w3jYgdKuwh7vg1Do_xtj0Kdt8JeFYTJSkjP1o',
        creator: { user: { username: 'JustinKan' } },
      },
      '63456874504071853140487069918655519200396087339641441958069865012086098100274': {
        supply: 50,
        token_id: '63456874504071853140487069918655519200396087339641441958069865012086098100274',
        name: 'We ALMOST started "TikTok" instead of Twitch?! | Certified Justin Kan Stories',
        image_url:
          'https://lh3.googleusercontent.com/43L6Lb2UkaSu0WYT9XWXYRPV-GNz8ZnTsfUgI7-CwxJuX88seC0hrV87rM93XZOJVELCSWmXjV0dRvoPWg9uds_6fcp0ahvdw7HvhA',
        creator: { user: { username: 'JustinKan' } },
      },
      '63456874504071853140487069918655519200396087339641441958069865010986586472545': {
        supply: 97,
        token_id: '63456874504071853140487069918655519200396087339641441958069865010986586472545',
        name: 'Selling Twitch to Amazon...the real story | Certified Justin Kan Stories',
        image_url:
          'https://lh3.googleusercontent.com/BVVwUS6Kq9XkmDM8B0ZWizisF2zzJqGswdXFfUAsTePoHPyDlZwlI5XqTqs0m7i4RDb8nKaZU1C2TwhJ-I8imMPkwJgK0ELJm2fyRBc',
        creator: { user: { username: 'JustinKan' } },
      },
    };

    if (walletAddress !== null && web3 && web3Loading !== true) {
      setNFTsLoading(true);
      let NFT_Contract = new web3.eth.Contract(erc1155ABI, NFT_Address);
      let web3_promises = [];
      ConfiguredNFTs.forEach((_id) => web3_promises.push(NFT_Contract.methods.balanceOf(walletAddress, _id).call()));
      Promise.all(web3_promises)
        .then((web3_results) => {
          let owned_NFTS = [];
          web3_results.forEach((value, index) => {
            if (value > 0) {
              let owned_NFT_object = NFT_Lookup[ConfiguredNFTs[index]];
              owned_NFT_object['owned_amount'] = value;
              owned_NFTS.push(owned_NFT_object);
            }
          });
          setOwnedNFTs(owned_NFTS);
          setNFTsLoading(false);
          if (owned_NFTS.length > 0) {
            setWalletOwnsNft(true);
          } else {
            setWalletOwnsNft(false);
          }
          //console.log('web3_results', web3_results);
        })
        .catch((error) => {
          //console.log(error);
          setNFTsLoading(false);
        });
    }
  }, [walletAddress, web3, web3Loading]);

  const unlockWeb3 = () => {
    setWeb3Loading(true);
    window.ethereum
      .request({ method: 'eth_requestAccounts' })
      .then((result) => {
        //console.log(result);
        setWalletAddress(result[0]);
        setWeb3(new Web3(window.ethereum));
        setCurrentStep(2);
        setWeb3Loading(false);
      })
      .catch((error) => {
        //console.log('eth_requestAccounts error', error);
        setWeb3Loading(false);
      });
  };

  const verifyOwnership = (token_id) => {
    setTokenVerifying(token_id);

    axios
      .get(`https://us-central1-justin-nft.cloudfunctions.net/api/get_verification_string/${walletAddress}/${token_id}`)
      .then((result) => {
        let verification_string = result.data;
        web3.eth.personal
          .sign(verification_string, walletAddress)
          .then((result) => {
            //console.log(result);
            let signature = result;
            axios
              .get(
                `https://us-central1-justin-nft.cloudfunctions.net/api/verify_signature/${walletAddress}/${signature}`
              )
              .then((result) => {
                let isVerified = result.data.isVerified;
                if (isVerified) {
                  setIsVerified(true);
                  setCurrentStep(3);
                  setTokenVerifying(null);
                  setVerifiedToken(token_id);
                  setZoomLink(result.data.redirectURL);
                } else {
                  setIsVerified(false);
                  setTokenVerifying(null);
                }
              })
              .catch((error) => {
                //console.log(error);
                setTokenVerifying(null);
              });
          })
          .catch((error) => {
            //console.log(error);
            setTokenVerifying(null);
          });
      });
  };

  React.useEffect(() => {
    if (walletAddress) {
      if (!isQuestionSubmitting) {
        setIsQuestionSubmitting(true);

        axios
          .post(`https://us-central1-justin-nft.cloudfunctions.net/api/get_question`, {
            address: walletAddress,
          })
          .then((result) => {
            let data = result.data;
            if (data.hasQuestion === true) {
              setIsQuestionSubmitting(false);
              setSubmittedQuestion({ name: data.question.name, question: data.question.question });
              setHasSubmittedQuestion(true);
              setIsEditing(false);
            } else {
              setIsQuestionSubmitting(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setIsQuestionSubmitting(false);
          });
      }
    }
  }, [walletAddress]);

  const deleteQuestion = () => {
    if (!isQuestionSubmitting) {
      setIsQuestionSubmitting(true);

      axios
        .post(`https://us-central1-justin-nft.cloudfunctions.net/api/delete_question`, {
          address: walletAddress,
        })
        .then((result) => {
          let data = result.data;
          if (data.status === true) {
            setIsQuestionSubmitting(false);
            setSubmittedQuestion({});
            setHasSubmittedQuestion(false);
          } else {
            setIsQuestionSubmitting(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsQuestionSubmitting(false);
        });
    }
  };

  const submitQuestion = () => {
    if (!isQuestionSubmitting) {
      setIsQuestionSubmitting(true);

      axios
        .post(`https://us-central1-justin-nft.cloudfunctions.net/api/submit_question`, {
          address: walletAddress,
          nft_id: verifiedToken,
          name: name,
          question: question,
        })
        .then((result) => {
          let data = result.data;
          if (data.status === true) {
            setIsQuestionSubmitting(false);
            setSubmittedQuestion({ name, question });
            setHasSubmittedQuestion(true);
            setIsEditing(false);
            setDisplayQuestion(false);
            setShowQuestionSubmittedMessage(true);
          } else {
            setIsQuestionSubmitting(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsQuestionSubmitting(false);
        });
    }
  };

  const step = (isCurrent, isCompleted, data) => {
    if (isCompleted) {
      return (
        <li className='relative overflow-hidden lg:flex-1'>
          <div className='border border-gray-200 overflow-hidden border-b-0 rounded-t-md lg:border-0'>
            <div className='group'>
              <span
                className='absolute top-0 left-0 w-1 h-full bg-transparent   lg:w-full lg:h-1 lg:bottom-0 lg:top-auto'
                aria-hidden='true'
              ></span>
              <span className='px-6 py-5 flex items-start text-sm font-medium'>
                <span className='flex-shrink-0'>
                  <span className='w-10 h-10 flex items-center justify-center bg-yellow-500  rounded-full'>
                    <svg
                      className='w-6 h-6 text-white'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                      aria-hidden='true'
                    >
                      <path
                        fillRule='evenodd'
                        d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                        clipRule='evenodd'
                      />
                    </svg>
                  </span>
                </span>
                <span className='mt-0.5 ml-4 min-w-0 flex flex-col'>
                  <span className='text-xs font-semibold tracking-wide uppercase'>{data.title}</span>
                  <span className='text-sm font-medium text-gray-500'>{data.subtitle}</span>
                </span>
              </span>
            </div>

            {data.step !== 1 && (
              <div className='hidden absolute top-0 left-0 w-3 inset-0 lg:block' aria-hidden='true'>
                <svg className='h-full w-full text-gray-300' viewBox='0 0 12 82' fill='none' preserveAspectRatio='none'>
                  <path d='M0.5 0V31L10.5 41L0.5 51V82' stroke='currentcolor' vectorEffect='non-scaling-stroke' />
                </svg>
              </div>
            )}
          </div>
        </li>
      );
    } else if (isCurrent) {
      return (
        <li className='relative overflow-hidden lg:flex-1'>
          <div className='border border-gray-200 overflow-hidden lg:border-0'>
            <div aria-current='step'>
              <span
                className='absolute top-0 left-0 w-1 h-full lg:w-full lg:h-1 lg:bottom-0 lg:top-auto'
                style={{ background: 'linear-gradient(to right, #12c2e9, #c471ed, #FAD6A6)' }}
                aria-hidden='true'
              ></span>
              <span className='px-6 py-5 flex items-start text-sm font-medium lg:pl-9'>
                <span className='flex-shrink-0'>
                  <span className='w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full'>
                    <span className='text-indigo-600'>0{data.step}</span>
                  </span>
                </span>
                <span className='mt-0.5 ml-4 min-w-0 flex flex-col'>
                  <span className='text-xs font-semibold text-indigo-600 tracking-wide uppercase'>{data.title}</span>
                  <span className='text-sm font-medium text-gray-500'>{data.subtitle}</span>
                </span>
              </span>
            </div>

            {data.step !== 1 && (
              <div className='hidden absolute top-0 left-0 w-3 inset-0 lg:block' aria-hidden='true'>
                <svg className='h-full w-full text-gray-300' viewBox='0 0 12 82' fill='none' preserveAspectRatio='none'>
                  <path d='M0.5 0V31L10.5 41L0.5 51V82' stroke='currentcolor' vectorEffect='non-scaling-stroke' />
                </svg>
              </div>
            )}
          </div>
        </li>
      );
    } else {
      return (
        <li className='relative overflow-hidden lg:flex-1'>
          <div className='border border-gray-200 overflow-hidden border-t-0 rounded-b-md lg:border-0'>
            <div className='group'>
              <span
                className='absolute top-0 left-0 w-1 h-full bg-transparent lg:w-full lg:h-1 lg:bottom-0 lg:top-auto'
                aria-hidden='true'
              ></span>
              <span className='px-6 py-5 flex items-start text-sm font-medium lg:pl-9'>
                <span className='flex-shrink-0'>
                  <span className='w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full'>
                    <span className='text-gray-500'>0{data.step}</span>
                  </span>
                </span>
                <span className='mt-0.5 ml-4 min-w-0 flex flex-col'>
                  <span className='text-xs font-semibold text-gray-500 tracking-wide uppercase'>{data.title}</span>
                  <span className='text-sm font-medium text-gray-500'>{data.subtitle}</span>
                </span>
              </span>
            </div>

            <div className='hidden absolute top-0 left-0 w-3 inset-0 lg:block' aria-hidden='true'>
              <svg className='h-full w-full text-gray-300' viewBox='0 0 12 82' fill='none' preserveAspectRatio='none'>
                <path d='M0.5 0V31L10.5 41L0.5 51V82' stroke='currentcolor' vectorEffect='non-scaling-stroke' />
              </svg>
            </div>
          </div>
        </li>
      );
    }
  };

  const NFTCard = (nft) => {
    return (
      <div
        key={nft.token_id}
        onClick={() => {
          verifyOwnership(nft.token_id);
        }}
        className={
          (tokenVerifying === nft.token_id ? 'filter:blur' : '') +
          'mb-4 flex flex-col rounded-lg shadow-lg overflow-hidden cursor-pointer hover:filter-blur'
        }
        style={{ maxWidth: '340px', maxHeight: '336px', filter: tokenVerifying === nft.token_id ? 'blur(1px)' : '' }}
      >
        {tokenVerifying === nft.token_id && (
          <div className='absolute' style={{ transform: 'translate(160px, 90px)' }}>
            <svg
              className='animate-spin h-5 w-5 text-white'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
            >
              <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
              <path
                className='opacity-75'
                fill='currentColor'
                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
              ></path>
            </svg>
          </div>
        )}
        <div className='flex-shrink-0'>
          <img className='h-48 w-full object-cover' src={nft.image_url} alt='' />
        </div>
        <div className='flex-1 bg-white p-6 flex flex-col justify-between'>
          <div className='flex-1'>
            <div className='text-sm font-medium text-indigo-600'>
              <div className=' '>
                {nft.token_id === '63456874504071853140487069918655519200396087339641441958069865013185609728001' ||
                nft.token_id === '63456874504071853140487069918655519200396087339641441958069865007688051589121' ||
                nft.token_id === '63456874504071853140487069918655519200396087339641441958069865006588539961345'
                  ? 'Single Edition'
                  : 'Multiple Editions'}
              </div>
            </div>
            <div className='block mt-2'>
              <p className='text-xl font-semibold text-gray-900' style={{ maxHeight: '56px', overflow: 'hidden' }}>
                {nft.name}
              </p>
              <p className='mt-8 text-base text-gray-500' style={{ maxHeight: '140px', overflow: 'hidden' }}>
                Whether it’s sharing startup inspiration, finding my path to happiness, just behind the scenes stories
                from Twitch, Justin.tv, and more, I’ve been having a lot of fun sharing the real stories and backstage
                look into the ups and downs of my life and learnings. So, I thought it would be fun to let you all in on
                the fun and launched Certified Justin Kan Stories, so you can collect stories that you love from my
                YouTube channel.
              </p>
            </div>
          </div>
          <div className='mt-6 flex items-center'>
            <div className='flex-shrink-0'>
              <div>
                <span className='sr-only'>{nft.creator.user.username}</span>
                <img className='h-10 w-10 rounded-full' src={JustinKanProfileImage} alt='' />
              </div>
            </div>
            <div className='ml-3'>
              <div className='text-sm font-medium text-gray-900'>
                <div>{nft.creator.user.username}</div>
              </div>
              <div className='flex space-x-1 text-sm text-gray-500'>{'https://opensea.io/collection/justinkan'}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const nftDisplay = ownedNFTs.map((nft) => NFTCard(nft));

  return (
    <div className='min-h-screen bg-white'>
      <nav className='bg-white shadow-sm'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
          <div className='flex justify-between h-16'>
            <div className='flex'>
              <div className='flex-shrink-0 flex items-center'>
                <img className='block lg:hidden h-8 w-auto' src={JustinKanProfileImage} alt='Workflow' />
                <img className='hidden lg:block h-8 w-auto' src={JustinKanProfileImage} alt='Workflow' />
                <h1 className='ml-4 text-2xl font-bold leading-tight text-gray-900'>Certified Justin Kan Stories</h1>
              </div>
            </div>

            <div className='hidden sm:ml-6 sm:flex sm:items-center'>
              {!hasWallet && (
                <div className='flex items-start'>
                  <div className='flex-shrink-0 h-5 w-5 relative flex items-center justify-center' aria-hidden='true'>
                    <div className='h-2 w-2 bg-gray-300 rounded-full  '></div>
                  </div>
                  <p className='ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900'>web3</p>
                </div>
              )}
              {hasWallet && (
                <div className='flex items-start'>
                  <div className='flex-shrink-0 h-5 w-5 relative flex items-center justify-center' aria-hidden='true'>
                    <div className='h-2 w-2 bg-green-200 rounded-full '></div>
                  </div>
                  <p className='ml-3 text-sm font-medium text-gray-900 group-hover:text-gray-900'>web3</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
      <div
        className=''
        style={{
          minHeight: '6px',
          maxHeight: '6px',
          height: '6px',
          width: '100%',
          background: 'linear-gradient(to right, #12c2e9, #c471ed, #FAD6A6)',
        }}
      ></div>

      <div className='py-10'>
        <header>
          <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
            {/*
        <h1 className="text-3xl font-bold leading-tight text-gray-900">
          Dashboard
        </h1>
        */}
          </div>
        </header>

        <main>
          <div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
            {/* Replace with your content */}

            <div className='mt-4 lg:border-t lg:border-b lg:border-gray-200'>
              <nav className='mx-auto max-w-7xl ' aria-label='Progress'>
                <ol className='rounded-md overflow-hidden lg:flex lg:border-l lg:border-r lg:border-gray-200 lg:rounded-none'>
                  {step(currentStep === 1, currentStep > 1, {
                    step: 1,
                    title: 'Connect Wallet',
                    subtitle: 'View NFTs',
                  })}

                  {step(currentStep === 2, currentStep > 2, {
                    step: 2,
                    title: 'Verify Ownership',
                    subtitle: 'Submit signature',
                  })}

                  {step(currentStep === 3, currentStep > 3, {
                    step: 3,
                    title: 'Access Zoom Link',
                    subtitle: 'Receive link',
                  })}
                </ol>
              </nav>
            </div>

            <div className='px-4 py-8 sm:px-0'>
              <div
                className={
                  walletAddress
                    ? 'rounded-lg h-96'
                    : 'bg-gray-200 border-4 border-dashed border-gray-200 rounded-lg h-96'
                }
              >
                {hasWallet && !walletAddress && (
                  <div
                    className='h-full w-full flex justify-center items-center'
                    style={{ minHeight: '376px', maxHeight: '376px' }}
                  >
                    <div
                      className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6'
                      role='dialog'
                      aria-modal='true'
                      aria-labelledby='modal-headline'
                    >
                      <div>
                        <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full '>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            height='66'
                            viewBox='0 0 35 33'
                            width='70'
                          >
                            <g strokeLinecap='round' strokeLinejoin='round' strokeWidth='.25'>
                              <path d='m32.9582 1-13.1341 9.7183 2.4424-5.72731z' fill='#e17726' stroke='#e17726' />
                              <g fill='#e27625' stroke='#e27625'>
                                <path d='m2.66296 1 13.01714 9.809-2.3254-5.81802z' />
                                <path d='m28.2295 23.5335-3.4947 5.3386 7.4829 2.0603 2.1436-7.2823z' />
                                <path d='m1.27281 23.6501 2.13055 7.2823 7.46994-2.0603-3.48166-5.3386z' />
                                <path d='m10.4706 14.5149-2.0786 3.1358 7.405.3369-.2469-7.969z' />
                                <path d='m25.1505 14.5149-5.1575-4.58704-.1688 8.05974 7.4049-.3369z' />
                                <path d='m10.8733 28.8721 4.4819-2.1639-3.8583-3.0062z' />
                                <path d='m20.2659 26.7082 4.4689 2.1639-.6105-5.1701z' />
                              </g>
                              <path
                                d='m24.7348 28.8721-4.469-2.1639.3638 2.9025-.039 1.231z'
                                fill='#d5bfb2'
                                stroke='#d5bfb2'
                              />
                              <path
                                d='m10.8732 28.8721 4.1572 1.9696-.026-1.231.3508-2.9025z'
                                fill='#d5bfb2'
                                stroke='#d5bfb2'
                              />
                              <path d='m15.1084 21.7842-3.7155-1.0884 2.6243-1.2051z' fill='#233447' stroke='#233447' />
                              <path d='m20.5126 21.7842 1.0913-2.2935 2.6372 1.2051z' fill='#233447' stroke='#233447' />
                              <path d='m10.8733 28.8721.6495-5.3386-4.13117.1167z' fill='#cc6228' stroke='#cc6228' />
                              <path d='m24.0982 23.5335.6366 5.3386 3.4946-5.2219z' fill='#cc6228' stroke='#cc6228' />
                              <path
                                d='m27.2291 17.6507-7.405.3369.6885 3.7966 1.0913-2.2935 2.6372 1.2051z'
                                fill='#cc6228'
                                stroke='#cc6228'
                              />
                              <path
                                d='m11.3929 20.6958 2.6242-1.2051 1.0913 2.2935.6885-3.7966-7.40495-.3369z'
                                fill='#cc6228'
                                stroke='#cc6228'
                              />
                              <path d='m8.392 17.6507 3.1049 6.0513-.1039-3.0062z' fill='#e27525' stroke='#e27525' />
                              <path d='m24.2412 20.6958-.1169 3.0062 3.1049-6.0513z' fill='#e27525' stroke='#e27525' />
                              <path
                                d='m15.797 17.9876-.6886 3.7967.8704 4.4833.1949-5.9087z'
                                fill='#e27525'
                                stroke='#e27525'
                              />
                              <path
                                d='m19.8242 17.9876-.3638 2.3584.1819 5.9216.8704-4.4833z'
                                fill='#e27525'
                                stroke='#e27525'
                              />
                              <path
                                d='m20.5127 21.7842-.8704 4.4834.6236.4406 3.8584-3.0062.1169-3.0062z'
                                fill='#f5841f'
                                stroke='#f5841f'
                              />
                              <path
                                d='m11.3929 20.6958.104 3.0062 3.8583 3.0062.6236-.4406-.8704-4.4834z'
                                fill='#f5841f'
                                stroke='#f5841f'
                              />
                              <path
                                d='m20.5906 30.8417.039-1.231-.3378-.2851h-4.9626l-.3248.2851.026 1.231-4.1572-1.9696 1.4551 1.1921 2.9489 2.0344h5.0536l2.962-2.0344 1.442-1.1921z'
                                fill='#c0ac9d'
                                stroke='#c0ac9d'
                              />
                              <path
                                d='m20.2659 26.7082-.6236-.4406h-3.6635l-.6236.4406-.3508 2.9025.3248-.2851h4.9626l.3378.2851z'
                                fill='#161616'
                                stroke='#161616'
                              />
                              <path
                                d='m33.5168 11.3532 1.1043-5.36447-1.6629-4.98873-12.6923 9.3944 4.8846 4.1205 6.8983 2.0085 1.52-1.7752-.6626-.4795 1.0523-.9588-.8054-.622 1.0523-.8034z'
                                fill='#763e1a'
                                stroke='#763e1a'
                              />
                              <path
                                d='m1 5.98873 1.11724 5.36447-.71451.5313 1.06527.8034-.80545.622 1.05228.9588-.66255.4795 1.51997 1.7752 6.89835-2.0085 4.8846-4.1205-12.69233-9.3944z'
                                fill='#763e1a'
                                stroke='#763e1a'
                              />
                              <path
                                d='m32.0489 16.5234-6.8983-2.0085 2.0786 3.1358-3.1049 6.0513 4.1052-.0519h6.1318z'
                                fill='#f5841f'
                                stroke='#f5841f'
                              />
                              <path
                                d='m10.4705 14.5149-6.89828 2.0085-2.29944 7.1267h6.11883l4.10519.0519-3.10487-6.0513z'
                                fill='#f5841f'
                                stroke='#f5841f'
                              />
                              <path
                                d='m19.8241 17.9876.4417-7.5932 2.0007-5.4034h-8.9119l2.0006 5.4034.4417 7.5932.1689 2.3842.013 5.8958h3.6635l.013-5.8958z'
                                fill='#f5841f'
                                stroke='#f5841f'
                              />
                            </g>
                          </svg>
                        </div>
                        <div className='mt-3 text-center sm:mt-5'>
                          <h3 className='text-lg leading-6 font-medium text-gray-900' id='modal-headline'>
                            Connect Metamask
                          </h3>
                          <div className='mt-2'>
                            <p className='text-sm text-gray-500'>
                              Connect your metamask to allow the website to view your NFTs. This will not allow the
                              website to send transactions from your wallet.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='mt-5 sm:mt-6'>
                        <button
                          onClick={() => unlockWeb3()}
                          type='button'
                          className='inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-yellow-500 text-base font-medium text-white hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:text-sm'
                        >
                          {!web3Loading && 'Connect Wallet'}
                          {web3Loading && (
                            <svg
                              className='animate-spin h-5 w-5 text-white'
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                            >
                              <circle
                                className='opacity-25'
                                cx='12'
                                cy='12'
                                r='10'
                                stroke='currentColor'
                                strokeWidth='4'
                              ></circle>
                              <path
                                className='opacity-75'
                                fill='currentColor'
                                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                              ></path>
                            </svg>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {currentStep === 2 && (
                  <div
                    className='flex-wrap	flex justify-evenly bg-gray-200 border-4 border-dashed border-gray-200 rounded-lg p-4'
                    style={{ minHeight: '376px' }}
                  >
                    {NFTsLoading && (
                      <div className='w-full h-full flex justify-center items-center'>
                        <svg
                          className='animate-spin h-5 w-5 text-white'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                        >
                          <circle
                            className='opacity-25'
                            cx='12'
                            cy='12'
                            r='10'
                            stroke='currentColor'
                            strokeWidth='4'
                          ></circle>
                          <path
                            className='opacity-75'
                            fill='currentColor'
                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                          ></path>
                        </svg>
                      </div>
                    )}
                    {!NFTsLoading && nftDisplay}
                  </div>
                )}
                {currentStep === 3 && (
                  <div
                    className='  flex justify-center items-center border-4 border-dashed bg-gray-200 border-gray-200 rounded-lg p-4'
                    style={{ minHeight: '376px', maxHeight: '376px' }}
                  >
                    {!displayQuestion && (
                      <>
                        <div
                          className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6'
                          role='dialog'
                          aria-modal='true'
                          aria-labelledby='modal-headline'
                        >
                          <div>
                            <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100'>
                              <svg
                                className='h-6 w-6 text-green-600'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                stroke='currentColor'
                                aria-hidden='true'
                              >
                                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M5 13l4 4L19 7' />
                              </svg>
                            </div>
                            <div className='mt-3 text-center sm:mt-5'>
                              <h3 className='text-lg leading-6 font-medium text-gray-900' id='modal-headline'>
                                {showQuestionSubmittedMessage ? 'Question submitted' : 'Verification successful'}
                              </h3>
                              <div className='mt-2'>
                                <p className='text-sm text-gray-500'>You can now join the shareholder meeting.</p>
                              </div>
                            </div>
                          </div>
                          <div className='mt-5 sm:mt-6'>
                            <a
                              href={zoomLink ? zoomLink : ''}
                              type='button'
                              className='inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-yellow-500 text-base font-medium text-white hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:text-sm'
                            >
                              Join Zoom meeting
                            </a>
                          </div>
                          <div className='mt-3 sm:mt-3'>
                            <button
                              onClick={() => setDisplayQuestion(true)}
                              className='inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-yellow-500 text-base font-medium border-yellow-500 border-2 hover:border-yellow-400 hover:text-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:text-sm'
                            >
                              {hasSubmittedQuestion ? 'View submitted question' : 'Submit question'}
                            </button>
                          </div>
                        </div>
                      </>
                    )}

                    {/* QUESTION FORM */}

                    {displayQuestion && (
                      <div className='bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6'>
                        <div className='mt-5 md:mt-0 md:col-span-2'>
                          <form className='space-y-6' action='#' method='POST'>
                            <div className='grid grid-cols-3 gap-3'>
                              <div className='col-span-3 sm:col-span-2'>
                                <label htmlFor='company_website' className='block text-sm font-medium text-gray-700'>
                                  Name
                                </label>
                                <div className='mt-1 flex rounded-md shadow-sm'>
                                  <input
                                    disabled={hasSubmittedQuestion && !isEditing ? true : false}
                                    type='text'
                                    name='name'
                                    id='name'
                                    className={
                                      'focus:ring-yellow-500 focus:border-yellow-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300' +
                                      (hasSubmittedQuestion && !isEditing ? ' bg-gray-100' : '')
                                    }
                                    placeholder='Your name'
                                    value={hasSubmittedQuestion && !isEditing ? submittedQuestion.name : name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>

                            <div>
                              <label htmlFor='about' className='block text-sm font-medium text-gray-700'>
                                Question
                              </label>
                              <div className='mt-1'>
                                <textarea
                                  disabled={hasSubmittedQuestion && !isEditing ? true : false}
                                  id='question'
                                  name='question'
                                  rows={3}
                                  maxLength={250}
                                  className={
                                    'shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-300 rounded-md' +
                                    (hasSubmittedQuestion && !isEditing ? ' bg-gray-100' : '')
                                  }
                                  placeholder='Ask Justin a question...'
                                  defaultValue={''}
                                  value={hasSubmittedQuestion && !isEditing ? submittedQuestion.question : question}
                                  style={{ resize: 'none' }}
                                  onChange={(e) => setQuestion(e.target.value)}
                                />
                              </div>
                              <div className='flex justify-end items-center w-full'>
                                <p className='mt-2 text-sm text-gray-500'>{`${
                                  hasSubmittedQuestion && !isEditing
                                    ? submittedQuestion.question.length
                                    : question.length
                                }/250`}</p>
                              </div>
                              <div className='flex mt-2 w-full justify-end items-center'>
                                <button
                                  onClick={() => {
                                    setDisplayQuestion(false);
                                    setIsEditing(false);
                                  }}
                                  type='button'
                                  className='inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-yellow-500 bg-yellow-100 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                                >
                                  Cancel
                                </button>

                                <button
                                  onClick={() =>
                                    hasSubmittedQuestion && !isEditing ? setIsEditing(true) : submitQuestion()
                                  }
                                  type='button'
                                  className='inline-flex items-center ml-2 px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                                >
                                  {hasSubmittedQuestion && !isEditing ? 'Edit' : 'Submit'}
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}

                    {/* ============= */}
                  </div>
                )}
              </div>
            </div>

            {/* /End replace */}
          </div>
        </main>
      </div>
    </div>
  );
}

export default App;
